<script>
import TextField from '../inputs/text.vue'

export default {
  extends: TextField,
  props: {
    rules: {
      type: String|Object,
      default: 'required|min:4'
    },
    label: {
      type: String,
      default: 'Zip Code'
    },
    name: {
      type: String,
      default: 'zip_code'
    }
  },
  data() {
    return {
      rulesToUse: this.rules
    }
  }
}
</script>
