<script>
import TextField from '../inputs/text.vue'

export default {
  extends: TextField,
  props: {
    label: {
      type: String,
      default: 'City'
    },
    name: {
      type: String,
      default: 'city'
    },
    rules: {
      type: String,
      default: 'required|min:3'
    }
  }
}
</script>
